import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { getEmployeeRatings } from '@/api/employees';
import PageBaseModule from '@/store/page';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { UITable } from '@/lib/util/tableUtils';
import { strings } from '@/lib/stringConst';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { CatchFormResponse } from '@/interfaces/shared';

const MODULE_NAME = 'employeeRatings';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
export class EmployeeRatingsModule extends PageBaseModule {
  employeeId = '';
  isTableLoading = true;

  constructor(module: EmployeeRatingsModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'employeeRatings/updatePage';
    this.pageSettings = page.values;
  }

  @Mutation
  SET_EMPLOYEE_ID(id: string): void {
    this.employeeId = id;
  }

  @Mutation
  SET_IS_TABLE_LOAD(bool: boolean): void {
    this.isTableLoading = bool;
  }

  @Action({ rawError: true })
  async init(employeeId: string): Promise<void> {
    await this.context.commit('SET_EMPLOYEE_ID', employeeId);

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.getList();
  }

  @Action({ rawError: true })
  async getList(): Promise<void> {
    const table = await this.fetchEmployeeRatings();

    if (table) {
      const preparedList = await this.prepareList(table);
      this.context.commit('SET_TABLE', preparedList);
    }
  }

  @Action({ rawError: true })
  async fetchEmployeeRatings(): Promise<TableApiInterface | void> {
    try {
      this.context.commit('SET_IS_TABLE_LOAD', true);

      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getEmployeeRatings(this.employeeId, this.pageSettings.pageCurrent, itemsQuery);

      return result;
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse).response?.data?.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    } finally {
      this.context.commit('SET_IS_TABLE_LOAD', false);
    }
  }

  @Action({ rawError: true })
  async prepareList(table: TableApiInterface): Promise<Partial<UITable>> {
    const uiTable = new UITable(table);

    return uiTable.setProperty('mark', 'align', 'center').getTable();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string): Promise<void> {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }
}

export default getModule(EmployeeRatingsModule);
